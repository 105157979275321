<template>
  <f-card>
    <v-row>
      <v-col cols="6">
        <v-select
          outlined
          dense
          :items="items"
          item-text="type"
          item-value="id"
          hide-details="auto"
          v-model="type"
          :label="$t('labels.type')"
        />
      </v-col>
      <v-col cols="6" />
    </v-row>
  </f-card>
</template>

<script>
import mktTypes from "../../../data/mktTypes";

export default {
  created() {
    this.language = localStorage.getItem("currentLang") || "pt";
  },
  computed: {
    items() {
      let items = this.$store.state.mktItems.view.baseData.types;
      console.log(this.$store.state.mktItems.view.baseData.types)
      return items.map(
        (item) => {
          return {
            id: item.id,
            type: mktTypes[this.language][item.id] || "Inválido",
          };
        }
      )
    },
    type: {
      get() {
        console.log(this.$store.state.mktItems.view.type)
        return this.$store.state.mktItems.view.type;
      },
      set(value) {
        this.$store.commit("mktItems/setViewType", value);
      },
    },
  },
};
</script>
