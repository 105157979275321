<template>
  <f-card>
    <v-row>
      <v-col cols="4">
        <v-switch
          :disabled="false"
          hide-details
          v-model="item.active"
          :label="$t('labels.active')"
        />
        <br />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <f-card-title>{{ $t('labels.language') }}</f-card-title>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <f-language-selector />
        <br />
      </v-col>
      <v-col cols="6" />
    </v-row>
    <v-row>
      <v-col cols="12">
        <f-card-title>{{ $t('labels.data') }}</f-card-title>
      </v-col>
    </v-row>
    <v-row v-if="isHighlightGroup(item.type)">
      <v-col cols="12">
        <v-file-input
          v-if="!flags.editing"
          @change="setFile"
          outlined
          :hint="$t('labels.hintSizeSugested', { size: '415x240'})"
          persistent-hint
          dense
        />

        <image-uploader v-if="flags.editing" v-model="item.image" />
      </v-col>
    </v-row>
    <v-row v-if="isVerticalGroup(item.type)">
      <v-col cols="12">
        <v-file-input
          v-if="!flags.editing"
          @change="setFile"
          outlined
          :hint="$t('labels.hintSizeSugested', { size: '270x210'})"
          persistent-hint
          dense
        />

        <image-uploader v-if="flags.editing" v-model="item.image" />

        <br v-if="!flags.editing" />
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="(it, index) in languages" :key="index">
            <div class="input-spacer">
              <f-editor
                ref="editor"
                v-model="item.long_description[it.abbreviation]"
              />
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
    <v-row v-if="itemType == 'event'">
      <v-col cols="12">
        <v-file-input
          v-if="!flags.editing"
          @change="setFile"
          outlined
          :hint="$t('labels.hintSizeSugested', { size: '400x255'})"
          persistent-hint
          dense
          label="Imagem"
        />

        <image-uploader v-if="flags.editing" v-model="item.image" />

        <br v-if="!flags.editing" />
        <v-dialog
          ref="dialog"
          v-model="modal"
          :return-value.sync="item.date"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="item.date"
              label="Data do evento"
              prepend-icon="mdi-calendar"
              readonly
              outlined
              hide-details
              dense
              v-bind="attrs"
              v-on="on"
            />
          </template>

          <v-date-picker v-model="item.date" scrollable>
            <v-spacer />
            <v-btn text color="primary" @click="modal = false">{{ $t('labels.btnCancel') }}</v-btn>
            <v-btn text color="primary" @click="$refs.dialog.save(item.date)">
              {{ $t('labels.btnSave') }}
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <!-- {{tab}} -->
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="(it, index) in languages" :key="index">
            <div class="input-spacer">
              <!-- {{it.abbreviation}} -->
              <v-text-field
                v-model="item.title[it.abbreviation]"
                outlined
                dense
                hide-details="auto"
                :label="$t('labels.title') + ` (${it.abbreviation})`"
              />
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
      <v-col cols="6" />
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="(it, index) in languages" :key="index">
            <div class="input-spacer">
              <v-text-field
                v-model="item.description[it.abbreviation]"
                outlined
                dense
                hide-details="auto"
                :label="$t('labels.description') + ` (${it.abbreviation})`"
              />
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
      <v-col cols="6" />
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="(it, index) in languages" :key="index">
            <div class="input-spacer">
              <v-text-field
                v-model="item.link[it.abbreviation]"
                outlined
                dense
                hide-details="auto"
                :label="`Link (${it.abbreviation})`"
              />
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
    <v-row v-if="isVerticalGroup(item.type)">
      <v-col cols="12">
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="(it, index) in languages" :key="index">
            <div class="input-spacer">
              <v-text-field
                v-model="item.link_box[it.abbreviation]"
                outlined
                dense
                hide-details="auto"
                :label="`Link Box (${it.abbreviation})`"
              />
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <br />
        <f-card-title>{{ $t('labels.orgSearch') }}</f-card-title>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-combobox
          v-model="item.tags"
          :items="items"
          :search-input.sync="search"
          hide-selected
          label="Tags"
          multiple
          outlined
          dense
          persistent-hint
          small-chips
          deletable-chips
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  <div v-html="$t('pages.mkt.searchResult', { term: search })"></div>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-combobox>
      </v-col>
    </v-row>
  </f-card>
</template>

<script>
import FLanguageSelector from "./LanguageSelector.vue";
import ImageUploader from "../../shared/ImageUploader.vue";
import { isHighlightGroup, isVerticalGroup } from "../../../helpers/mkt_utils";

export default {
  components: {
    FLanguageSelector,
    ImageUploader,
  },
  data: () => ({
    items: [],
    model: [],
    search: null,
    modal: false,
    flags: {
      editing: false,
    },
    // date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
  }),
  computed: {
    tab() {
      return this.$store.state.mktItems.view.baseData.language - 1;
    },
    item() {
      return this.$store.state.mktItems.view;
    },
    itemType() {
      const types = {
        1: "highlight",
        2: "vertical",
        3: "event",
        4: "imageBank",
        5: "material",
        6: "calendar",
        7: "institutional",
        8: "saleTool",
        9: "ticket",
      };
      return types[this.item.type] || types.highlight;
    },
    languages() {
      return this.$store.state.languages.list;
    },
  },
  created() {
    this.getData();
    if (this.$route.name == "mkt-items-edit") {
      this.flags.editing = true;
    }
  },
  methods: {
    setFile(file) {
      this.item.file = file;
    },
    isHighlightGroup(type) {
      return isHighlightGroup(type);
    },
    isVerticalGroup(type) {
      return isVerticalGroup(type);
    },
    async getData() {
      this.$store.state.tags.list.map((item) => {
        this.items.push(item.title);
      });
    },
  },
};
</script>
