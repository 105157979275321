<template>
  <div class="f-image-uploader">
    <div class="image-holder-wrapper">
      <div class="icon" :class="{ active: side, removing: status == 'r' }">
        <div class="button" @click="pickFile">
          <v-icon>mdi-pencil</v-icon>
        </div>
        <div class="button" @click="remove">
          <v-icon>mdi-delete</v-icon>
        </div>
      </div>
      <div class="holder">
        <img v-if="status != 'e'" :src="imgValue" />
        <div v-else class="button" @click="pickFile">
          <v-icon>mdi-plus</v-icon>
        </div>
      </div>
    </div>
    <input type="file" ref="files" @change="editFile" style="display: none" />
  </div>
</template>

<script>
export default {
  props: ["value"],
  data: () => ({
    newFile: "",
    imgValue: "",
    innerValue: "",
    bckValue: "",
    side: false,
    status: "e", // e - empty / i - with image / r - remove
    firstLoad: true,
  }),
  watch: {
    value() {
      this.reloadImage();
    },
  },
  created() {
    this.reloadImage();
  },
  methods: {
    reloadImage() {
      if (this.firstLoad && this.value) {
        this.firstLoad = false;
        this.innerValue = this.value;
        this.imgValue = this.innerValue;

        if (this.innerValue !== "") {
          this.status = "i";
          this.side = true;
        }
      }
    },
    remove() {
      if (this.status == "i") {
        this.status = "r";
        this.bckValue = this.innerValue;
        this.innerValue = "";
      } else {
        this.status = "i";
        this.innerValue = this.bckValue;
      }

      this.$emit("input", this.innerValue);
    },
    pickFile() {
      this.$refs.files.click();
    },
    editFile() {
      var reader = new FileReader();
      reader.onload = function (e) {
        this.imgValue = e.target.result;
      }.bind(this);
      reader.readAsDataURL(this.$refs.files.files[0]);
      this.innerValue = this.$refs.files.files[0];

      this.status = "i";
      this.side = true;

      this.$emit("input", this.innerValue);
    },
  },
};
</script>

<style lang="scss">
.f-image-uploader {
  margin: 0 0 20px 0;
  .image-holder-wrapper {
    height: 100px;
    width: 100px;
    border-radius: 3px;
    background-color: #f5f5f5;
    position: relative;

    .holder {
      z-index: 2;
      position: absolute;
      background-color: #f5f5f5;
      width: 100%;
      height: 100%;
      border-radius: 3px;
      top: 0;
      left: 0;
      overflow: hidden;
      img {
        width: 100px;
        height: 100px;
        object-fit: cover;
      }

      .button {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
      }
    }

    .icon {
      z-index: 1;
      position: absolute;
      top: 0;
      width: 65px;
      height: 100px;
      background-color: #ffc107;
      border-radius: 3px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding-left: 5px;
      left: 30px;
      transition: all 0.8s;

      &.active {
        left: 95px;
      }

      &.removing {
        background-color: red;
      }

      .button {
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: background-color 0.2s;

        &:hover {
          background-color: rgba($color: #000000, $alpha: 0.3);
        }
      }
    }
  }
}
</style>
