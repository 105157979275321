<template>
  <div>
    <f-default-header :title="$t('pages.mkt.index.title')" :subtitle="flags.editing ? $t('pages.mkt.edit.subtitle') : $t('pages.mkt.new.subtitle')" />

    <f-type-selector />
    <div v-if="typeIsSelected">
      <f-card-spacer />
      <f-create-edit-form />
    </div>

    <f-actions-btn-holder>
      <v-btn
        color="secondary"
        elevation="0"
        large
        @click="back"
        :disabled="false"
      >
        {{ $t('labels.btnCancel') }}
      </v-btn>
      &nbsp;
      <v-btn
        color="primary"
        elevation="0"
        large
        @click="save"
        :disabled="sending"
        :loading="sending"
      >
        {{ true ? $t('labels.btnSave') : $t('labels.btnSaving') }}
      </v-btn>
    </f-actions-btn-holder>
  </div>
</template>

<script>
import FTypeSelector from "../../../components/mkt/items/TypeSelector.vue";
import FCreateEditForm from "../../../components/mkt/items/CreateEditForm.vue";

export default {
  components: {
    FTypeSelector,
    FCreateEditForm,
  },
  data: () => ({
    flags: {
      editing: false,
    },
    typeIsSelected: false,
  }),
  async created() {
    await this.$store.dispatch("mktItems/clear", "view");
    if (this.$route.name == "mkt-items-edit") {
      this.flags.editing = true;
    }
    this.getData();

    this.selInterval = setInterval(
      function () {
        this.checkSelected();
      }.bind(this),
      800
    );
  },
  methods: {
    checkSelected() {
      if (this.$store.state.mktItems.view.type) {
        this.typeIsSelected = true;
        return;
      }

      this.typeIsSelected = false;
    },
    async getData() {
      this.$store.dispatch("tags/list");
      this.$store.dispatch("mktItems/items_listTypes");
      await this.$store.dispatch("languages/list");
      this.$store.state.mktItems.view.baseData.language =
        this.$store.state.languages.list[0].id;

      if (this.flags.editing) {
        this.$store.dispatch("mktItems/items_get", this.$route.params.id);
      }
    },
    async save() {
      if (this.flags.editing) {
        await this.$store.dispatch("mktItems/edit");
      } else {
        await this.$store.dispatch("mktItems/save");
      }
      this.back();
    },
    back() {
      this.$store.dispatch("mktItems/clear", "view");
      this.$router.push({
        name: "mkt-items-list",
      });
    },
  },
};
</script>
