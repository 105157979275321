<template>
  <div>
    <v-select
      outlined
      dense
      :items="languages"
      item-text="language"
      item-value="id"
      hide-details="auto"
      v-model="language"
      :label="$t('labels.language')"
    />
  </div>
</template>

<script>
export default {
  computed: {
    languages() {
      return this.$store.state.languages.list;
    },
    language: {
      get() {
        return this.$store.state.mktItems.view.baseData.language;
      },
      set(value) {
        this.$store.commit("mktItems/setViewLanguage", value);
      },
    },
  },
};
</script>
